import React from 'react';
import parse from 'html-react-parser';

// import Button from '../Button';
import Container from '../Container';
import Row from '../Container/row';

import { LightGrey } from '../../utils/variables';
import Col from '../Container/column';
import LPSidebar from './sidebar';

export default function LPFullWidthTwo({ page }) {
  return (
    <section
      className="lpFullWidthTwo"
      style={{ padding: `60px 0`, backgroundColor: LightGrey }}
    >
      <Container>
        <Row justify="space-between" rowGap="45px">
          <Col xl={{ width: `50%` }}>
            {parse(page.landingPageLayout.fullWidth2)}
          </Col>
          <Col xl={{ width: `50%` }}>
            <LPSidebar
              text={page.landingPageLayout.sidebar.content}
              {...page.landingPageLayout.sidebar}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
