import React from 'react';
import parse from 'html-react-parser';

import Container from '../Container';

import { sectionSpacing } from './style';
import { MainColour } from '../../utils/variables';

export default function LPFullWidthThree({ page }) {
  return (
    <section
      className="landingPage fullWidthBg"
      style={{
        backgroundColor: MainColour,
        padding: `90px 0`,
        ...sectionSpacing,
      }}
    >
      <Container>{parse(page.landingPageLayout.fullWidth3)}</Container>
    </section>
  );
}
