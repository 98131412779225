import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import LPHeader from '../components/LandingPage/header';
import LPIntro from '../components/LandingPage/intro';
import LPColumns from '../components/LandingPage/columns';
import LPFullWidth from '../components/LandingPage/full-width';
import LPList from '../components/LandingPage/list';
import LPFullWidthColumns from '../components/LandingPage/full-width-columns';
import LPFullWidthTwo from '../components/LandingPage/full-width-2';
import LPTable from '../components/LandingPage/table';
import LPFullWidthThree from '../components/LandingPage/full-width-3';
import LPFaq from '../components/LandingPage/faq';

import '../components/LandingPage/style.scss';
import LPButton from '../components/LandingPage/button';

export default function LandingPageTemplate({ location, data }) {
  const previousPage = location.state && location.state.prevPage;
  const page = data.wpLandingPage;

  const parent = page.hierarchy.parentPage;

  const landingSlug = parent ? `/${parent}/${page.slug}/` : `/${page.slug}/`;

  const pageProps = {
    page,
  };

  return (
    <Layout
      title={page.title}
      seo={page.seo}
      location={landingSlug}
      prevPage={previousPage}
      padding="0 0 0"
      noButton
      landingPage
    >
      <article className="landingPage">
        <LPHeader header={page.landingPageLayout.headerNew} />
        <LPIntro content={page.landingPageLayout.introduction} />
        <LPColumns {...pageProps} />
        <LPFullWidth {...pageProps} />
        <LPList {...pageProps} />
        <LPFullWidthColumns {...pageProps} />
        <LPButton {...pageProps} />
        <LPFullWidthTwo {...pageProps} />
        <LPTable {...pageProps} />
        <LPFullWidthThree {...pageProps} />
        <LPFaq {...pageProps} />
      </article>
    </Layout>
  );
}

export const landingPageQuery = graphql`
  query ($id: String!) {
    wpLandingPage(id: { eq: $id }) {
      title
      slug
      hierarchy {
        parentPage
      }
      ...LandingPageSeo
      ...LandingPageHeader
      ...LandingPageContent
    }
  }
`;
