import React from 'react';
import parse from 'html-react-parser';

import Container from '../Container';

import { sectionSpacing } from './style';

export default function LPIntro({ content }) {
  return (
    <section className="lpIntro" style={sectionSpacing}>
      <Container>{parse(content)}</Container>
    </section>
  );
}
