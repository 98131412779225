import React from 'react';
import parse from 'html-react-parser';

import Container from '../Container';
import TitleH2 from '../Title/h2';
import Faq from '../Faq';

import { sectionSpacing } from './style';

export default function LPFaq({ page }) {
  return (
    <section
      className="lpFaq"
      style={{ ...sectionSpacing, paddingBottom: `60px` }}
    >
      <Container>
        <TitleH2>
          {parse(page.landingPageLayout.frequentlyAskedQuestions.title)}
        </TitleH2>
        {page.landingPageLayout.frequentlyAskedQuestions.content &&
          parse(page.landingPageLayout.frequentlyAskedQuestions.content)}
        <Faq
          data={page.landingPageLayout.frequentlyAskedQuestions.faq.map(faq => {
            return {
              header: faq.question,
              content: parse(faq.answer),
            };
          })}
        />
      </Container>
    </section>
  );
}
