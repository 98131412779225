import React from 'react';
import parse from 'html-react-parser';
import { getWindow } from '@web-passion/uikit';

import Button from '../Button';

import { LightBlue } from '../../utils/variables';
import { SidebarCaret } from '../Icons/icons';

export default function LPSidebar({ text, buttonLink, buttonText }) {
  const { windowMD } = getWindow();
  return (
    <div
      className="lpSidebar"
      style={{
        backgroundColor: LightBlue,
        padding: `45px`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        width: `100%`,
      }}
    >
      {parse(text)}
      <Button
        to={buttonLink}
        style={{ justifyContent: `space-between` }}
        size="large"
        primary
      >
        {buttonText}
        {windowMD && (
          <div style={{ transform: `rotate(90deg)`, marginLeft: `auto` }}>
            <SidebarCaret size="8px" color="#fff" />
          </div>
        )}
      </Button>
    </div>
  );
}
