import React from 'react';
import parse from 'html-react-parser';
import { getWindow } from '@web-passion/uikit';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';
import TitleH1 from '../Title';
import Button from '../Button';

import { MainColour } from '../../utils/variables';
import { SidebarCaret } from '../Icons/icons';

import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

export default function LPHeader({ header }) {
  const { windowMD } = getWindow();
  return (
    <section
      className="lpHeader"
      style={{
        position: `relative`,
        display: `flex`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          ...floatStyle,
          backgroundColor: `rgba(0,0,0,0.4)`,
          zIndex: 2,
        }}
      />
      <Img
        image={header.imageNew?.localFile.childImageSharp.gatsbyImageData}
        alt={header.contentNew.titleNew}
        itemProp="image"
        style={{ ...floatStyle, zIndex: 1 }}
      />
      <Container style={{ zIndex: 5 }}>
        <Row>
          <Col lg={{ width: `586px` }}>
            <div
              style={{
                backgroundColor: `rgba(255,255,255,0.7)`,
                padding: `45px`,
              }}
            >
              <TitleH1 line={1.2} colour={MainColour}>
                {parse(header.contentNew.titleNew)}
              </TitleH1>
              <p>{parse(header.contentNew.textNew)}</p>
              <Button
                to={header.contentNew.buttonLinkNew}
                size={windowMD ? 'large' : 'medium'}
                primary
              >
                {header.contentNew.buttonTextNew}
                {windowMD && (
                  <div
                    style={{ transform: `rotate(90deg)`, marginLeft: `auto` }}
                  >
                    <SidebarCaret size="8px" color="#fff" />
                  </div>
                )}
              </Button>
              {header.contentNew.additionalNew && (
                <p style={{ marginTop: `30px` }}>
                  {parse(header.contentNew.additionalNew)}
                </p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const floatStyle = {
  position: `absolute`,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};
