import React from 'react';
import parse from 'html-react-parser';

import Container from '../Container';
import TitleH2 from '../Title/h2';

import { sectionSpacing } from './style';
import { LightBlue } from '../../utils/variables';

export default function LPList({ page }) {
  return (
    <section className="lpList" style={sectionSpacing}>
      <Container>
        <TitleH2>{parse(page.landingPageLayout.list.title)}</TitleH2>
        <p>{parse(page.landingPageLayout.list.content)}</p>
        {page.landingPageLayout.list.listItems.map((item, i) => {
          return (
            <div
              style={{
                padding: `15px 30px`,
                backgroundColor: i % 2 ? `#fff` : LightBlue,
              }}
            >
              <p>{parse(item.text)}</p>
            </div>
          );
        })}
      </Container>
    </section>
  );
}
