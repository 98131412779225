import React from 'react';
import parse from 'html-react-parser';

import Container from '../Container';
import TitleH2 from '../Title/h2';

import { sectionSpacing } from './style';

export default function LPTable({ page }) {
  return (
    <section className="lpTable" style={sectionSpacing}>
      <Container>
        <TitleH2>{parse(page.landingPageLayout.table.title)}</TitleH2>
        {parse(page.landingPageLayout.table.content)}
      </Container>
    </section>
  );
}
