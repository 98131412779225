import React from 'react';
import parse from 'html-react-parser';

import Container from '../Container';
import Col from '../Container/column';
import Row from '../Container/row';
import TitleH2 from '../Title/h2';

import { sectionSpacing } from './style';
import { MainColour } from '../../utils/variables';

export default function LPFullWidthColumns({ page }) {
  return (
    <section
      className="landingPage fullWidthBg fullWidthCols"
      style={{
        backgroundColor: MainColour,
        padding: `90px 0`,
        ...sectionSpacing,
      }}
    >
      <Container>
        <Row justify="space-between">
          <Col xl={{ width: `45%` }}>
            <TitleH2 colour="#fff">
              {parse(page.landingPageLayout.fullWidthColumns.leftCol.titleH2)}
            </TitleH2>
            {parse(page.landingPageLayout.fullWidthColumns.leftCol.content)}
          </Col>
          <Col xl={{ width: `45%` }}>
            <TitleH2 colour="#fff">
              {parse(page.landingPageLayout.fullWidthColumns.rightCol.titleH2)}
            </TitleH2>
            {parse(page.landingPageLayout.fullWidthColumns.rightCol.content)}
          </Col>
        </Row>
      </Container>
    </section>
  );
}
