import React from 'react';
import parse from 'html-react-parser';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';
import LPSidebar from './sidebar';
import TitleH2 from '../Title/h2';

import { sectionSpacing } from './style';

export default function LPColumns({ page }) {
  return (
    <section className="lpColumns" style={sectionSpacing}>
      <Container>
        <Row justify="space-between" rowGap="45px">
          <Col className="lpLeftCol" xl={{ width: `50%` }}>
            <TitleH2 line={1}>{page.landingPageLayout.leftCol.title}</TitleH2>
            {parse(page.landingPageLayout.leftCol.text)}
          </Col>
          <Col
            xl={{ width: `50%` }}
            style={{ display: `flex`, alignItems: `center` }}
          >
            <LPSidebar {...page.landingPageLayout.rightCol} />
          </Col>
          {page.landingPageLayout.additional && (
            <Col>{parse(page.landingPageLayout.additional)}</Col>
          )}
        </Row>
      </Container>
    </section>
  );
}
