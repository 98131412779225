import React from 'react';
import Button from '../Button';

export default function LPButton({ page }) {
  return (
    <section
      className="landingPage button"
      style={{
        padding: `60px 0`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
      }}
    >
      <Button to={page.landingPageLayout.button.link} primary>
        {page.landingPageLayout.button.text}
      </Button>
    </section>
  );
}
